import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import Buefy from 'buefy'
import vueSmoothScroll from 'vue2-smooth-scroll'
import AOS from 'aos'

// css
import 'buefy/dist/buefy.css'
import './sass/styles.scss'
import 'aos/dist/aos.css'

AOS.init({
  disable: false,
  startEvent: 'DOMContentLoaded',
  initClassName: 'aos-init',
  animatedClassName: 'aos-animate',
  useClassNames: false,
  disableMutationObserver: false,
  debounceDelay: 50,
  throttleDelay: 99,

  offset: 120,
  delay: 0,
  duration: 400,
  easing: 'ease',
  once: false,
  mirror: false,
  anchorPlacement: 'top-bottom'
})

Vue.config.productionTip = false

Vue.use(Buefy)
Vue.use(vueSmoothScroll)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
