<template>
  <div class="modal-section-divider">
      <div class="modal-card" style="width: auto">
        <section class="modal-card-body">
          <div class="modal-close-divider">
            <b-button icon-left="progress-close" type="is-text" size="is-medium" @click="triggerModal"></b-button>
          </div>
          <div class="modal-custom-header has-text-centered">
            <h3 class="custom-divider"><img class="custom-divider-image" src="../assets/images/construct/user.svg" alt="studiopixidream svg logo" height="50" width="50"></h3>
          </div>
          <div class="welcome-message-header has-text-centered">
            <h2 class="welcome-message">{{ $t('contact-form.say-hello.welcome-text') }}</h2>
          </div>
          <div class="block">
            <form @submit.prevent="sendMail" class="say-hello-form">
              <b-field label="Email" :type="isEmailValid()">
                <b-input type="email" placeholder="email@domain.com" v-model="contactFormData.email"></b-input>
              </b-field>
              <b-field :label="$t('contact-form.say-hello.name')" :type="isNameValid()">
                <b-input placeholder="John Doe" v-model="contactFormData.name"></b-input>
              </b-field>
              <b-field label="Message" :type="isMessageValid()">
                <b-input :placeholder="$t('contact-form.say-hello.message')" maxlength="500" type="textarea" v-model="contactFormData.message"></b-input>
              </b-field>
              <div class="columns cols-flex btn-center has-text-centered">
                <div class="column submit-captcha-col">
                  <vue-recaptcha sitekey="6LcTmAEVAAAAAMtjlhLjon3qBIOtP1gXM7t4QmUU" :loadRecaptchaScript="true" type="checkbox" theme="light" @verify="validateCaptcha"></vue-recaptcha>
                </div>
                <div class="column submit-btn-col">
                  <b-button type="is-primary submit is-rounded is-large submit-button" outlined :disabled="!validateData()" :loading="sending" @click="sendMail">{{ $t('contact-form.say-hello.submit') }}</b-button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'

export default {
  data () {
    return {
      contactFormData: {
        name: '',
        email: '',
        message: '',
        captchaVerified: false
      },
      sending: false,
      // eslint-disable-next-line no-useless-escape
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  methods: {
    validateData () {
      if (this.isEmailValid() === 'is-success' && this.isNameValid() === 'is-success' && (this.isMessageValid() === 'is-success' || this.isMessageValid() === 'is-warning')) {
        return true
      }
    },
    validateCaptcha (response) {
      this.contactFormData.captchaVerified = true
    },
    triggerModal () {
      this.$store.state.isSayHelloTrigger = !this.$store.state.isSayHelloTrigger
    },
    isEmailValid: function () {
      return (this.contactFormData.email === '') ? '' : (this.reg.test(this.contactFormData.email)) ? 'is-success' : 'is-danger'
    },
    isNameValid: function () {
      return (this.contactFormData.name === '') ? '' : (this.contactFormData.name.length > 4) ? 'is-success' : 'is-danger'
    },
    isMessageValid: function () {
      return (this.contactFormData.message === '') ? '' : (this.contactFormData.message.length < 20) ? 'is-danger' : (this.contactFormData.message.length > 450) ? 'is-warning' : 'is-success'
    },
    sendMail () {
      if (!this.contactFormData.captchaVerified) {
        this.$buefy.toast.open({
          message: this.$t('contact-form.say-hello.validate-captcha'),
          type: 'is-danger',
          duration: 5000
        })
      } else {
        this.sending = true
        const url = 'https://us-central1-studiopixidream.cloudfunctions.net/sendEmailCF'
        const { name, email, message } = this.contactFormData
        const payload = { name, email, message }
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        })
          .then(() => {
            this.$buefy.toast.open({
              message: this.$t('contact-form.say-hello.message-sent'),
              type: 'is-success',
              duration: 5000
            })
            this.resetForm()
            this.sending = false
          })
          .catch((e) => {
            this.$buefy.toast.open({
              message: this.$t('contact-form.say-hello.message-error', e),
              type: 'is-danger',
              duration: 5000
            })
            this.sending = false
          })
      }
    },
    resetForm: function () {
      this.contactFormData = {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  components: {
    VueRecaptcha
  }
}
</script>
