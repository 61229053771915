<template>
  <b-navbar class="main-navbar" fixed-top>
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }" class="main-nav-brand">
        <img src="../assets/images/Logo/LogoNoBrand.svg" alt="studiopixidream new logo color svg" height="48" width="48">
        <p class="brand-title">STUDIOPIXIDREAM</p>
      </b-navbar-item>
    </template>
    <template slot="end">
      <b-navbar-item tag="div" class="say-hello-div">
        <a class="button is-primary is-outlined say-hello-btn is-rounded" @click="triggerSayHello">{{ $t('main-header.sh-btn') }}</a>
      </b-navbar-item>
      <b-navbar-dropdown class="setting-menu material-icons" right label="settings" arrowless>
        <b-select placeholder="Language" icon="translate" v-model="$i18n.locale" class="trad-slct">
          <option
            v-for="(lang, i) in langs"
            :key="`Lang${i}`"
            :value="lang.key"
          >
            {{ lang.tag }}
          </option>
        </b-select>
        <b-button type="is-text" class="switch-theme-btn" @click="darkMode = !darkMode">
          <b-icon
            icon="white-balance-sunny"
            size="is-medium"
            v-if="darkMode"
          >
          </b-icon>
          <b-icon
            icon="weather-night"
            size="is-medium"
            v-else
          >
          </b-icon>
        </b-button>
      </b-navbar-dropdown>
      <b-navbar-item tag="div" class="mobile-setting lang">
        <b-switch
          v-model="$i18n.locale"
          :true-value="langs[0].key"
          :false-value="langs[1].key"
          :passive-type="passiveType"
        >
          {{ $i18n.locale === 'fr' ? 'Français' : 'English' }}
        </b-switch>
      </b-navbar-item>
      <b-navbar-item tag="div" class="mobile-setting darkmode">
        <b-switch
          v-model="darkMode"
          passive-type="is-dark"
          type="is-warning"
        >
          {{ darkMode ? $t('main-header.dark') : $t('main-header.light') }}
        </b-switch>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>
<script>
export default {
  data () {
    return {
      langs: [
        {
          key: 'en',
          tag: 'English'
        },
        {
          key: 'fr',
          tag: 'Français'
        }
      ],
      darkMode: true,
      passiveType: 'is-primary'
    }
  },
  mounted () {
    const bodyElement = document.body
    bodyElement.classList.add('app-background')

    const htmlElement = document.documentElement
    const theme = localStorage.getItem('theme')

    if (theme === 'dark') {
      htmlElement.setAttribute('theme', 'dark')
      this.darkMode = true
    } else {
      htmlElement.setAttribute('theme', 'light')
      this.darkMode = false
    }
  },
  watch: {
    darkMode: function () {
      const htmlElement = document.documentElement

      if (this.darkMode) {
        localStorage.setItem('theme', 'dark')
        htmlElement.setAttribute('theme', 'dark')
      } else {
        localStorage.setItem('theme', 'light')
        htmlElement.setAttribute('theme', 'light')
      }
    }
  },
  methods: {
    triggerSayHello () {
      this.$store.state.isSayHelloTrigger = !this.$store.state.isSayHelloTrigger
    }
  }
}
</script>
