<template>
  <main role="main">
    <section class="hero has-text-centered hero-main-section" id="hero-main">
      <div class="hero-body hero-main-section-body">
        <div class="container hero-main-section-container">
          <div class="columns is-centered hero-main-section-columns">
            <div class="column hero-main-section-column">
              <img data-aos="fade-right" data-aos-duration="500" class="hero-main-svg" src="../assets/images/construct/undraw_programming_2svr.svg" alt="undraw programming svg">
            </div>
            <div class="column hero-main-section-column">
              <h1 class="hero-main-title" data-aos="fade-left" data-aos-duration="2000">{{ $t('homepage.hero-section.apprentice-title') }}</h1>
              <p class="hero-main-subtitle" data-aos="fade-left" data-aos-duration="2000">{{ $t('homepage.hero-section.apprentice-subtitle') }}</p>
            </div>
          </div>
          <div class="column hero-scrolldown">
            <a class="hero-scrolldown-btn" href="#prez" v-smooth-scroll="{ duration: 1500, offset: -50}">
              <span></span>
              {{ $t('homepage.hero-section.scroll-btn') }}
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-medium is-primary has-text-centered is-long section-prez" id="prez">
      <div class="container section-prez-container">
        <div class="columns is-centered section-prez-columns">
          <div class="column is-one-fifth section-prez-image-column">
            <img
              data-aos="fade-up"
              data-aos-duration="500"
              class="section-prez-image"
              src="../assets/images/construct/PP.jpg"
              alt="photo françois lavigne marbach noir et blanc, picture black and white"
            >
          </div>
          <div class="column is-three-fifths section-prez-text-column">
            <h1
              class="section-prez-title"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              {{ $t('homepage.prez-section.prez-title') }}
            </h1>
            <p
              class="section-prez-text"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              {{ $t('homepage.prez-section.prez-text') }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section-knowledge has-text-centered">
      <div class="container is-narrow section-knowledge-container">
        <img data-aos="fade-down" src="../assets/images/construct/undraw_version_control_9bpv.svg" alt="undraw version control svg" class="section-knowledge-git-pict">
        <img data-aos="fade-up" src="../assets/images/construct/undraw_web_developer_p3e5.svg" alt="undraw version control svg" class="section-knowledge-webdev-pict">
        <div class="box section-knowledge-box" data-aos="fade-up">
          <div class="content section-knowledge-content">
            <div class="columns is-centered section-knowledge-columns">
              <div class="column section-knowledge-left-column">
                <img data-aos="zoom-in" class="knowledge-pict" src="../assets/images/construct/algorithm.svg" alt="game dev svg picture" />
                <h3 data-aos="zoom-in" class="knowledge-title">{{ $t('homepage.knowledge-section.game-section.title') }}</h3>
                <p data-aos="zoom-in" class="knowledge-subtitle">{{ $t('homepage.knowledge-section.game-section.subtitle') }}</p>
                <h4 data-aos="zoom-in" class="knowledge-section-title">{{ $t('homepage.knowledge-section.game-section.lang') }}</h4>
                <p data-aos="zoom-in" class="knowledge-lang">C++, Python, Javascript</p>
                <h4 data-aos="zoom-in" class="knowledge-section-title mb-08">{{ $t('homepage.knowledge-section.game-section.tech') }}</h4>
                <li data-aos="zoom-in" v-for="tech in knowlegde.gamedev.techs" :key="tech" class="knowledge-tech">{{ tech }}</li>
              </div>
              <div class="column section-knowledge-center-column">
                <img data-aos="zoom-in" class="knowledge-pict" src="../assets/images/construct/game-development.svg" alt="game dev svg picture" />
                <h3 data-aos="zoom-in" class="knowledge-title">{{ $t('homepage.knowledge-section.soft-section.title') }}</h3>
                <p data-aos="zoom-in" class="knowledge-subtitle">{{ $t('homepage.knowledge-section.soft-section.subtitle') }}</p>
                <h4 data-aos="zoom-in" class="knowledge-section-title">{{ $t('homepage.knowledge-section.soft-section.lang') }}</h4>
                <p data-aos="zoom-in" class="knowledge-lang">C++, Python, Javascript</p>
                <h4 data-aos="zoom-in" class="knowledge-section-title mb-08">{{ $t('homepage.knowledge-section.game-section.tech') }}</h4>
                <li data-aos="zoom-in" v-for="tech in knowlegde.softdev.techs" :key="tech" class="knowledge-tech">{{ tech }}</li>
              </div>
              <div class="column section-knowledge-right-column">
                <img data-aos="zoom-in" class="knowledge-pict" src="../assets/images/construct/web-development.svg" alt="game dev svg picture" />
                <h3 data-aos="zoom-in" class="knowledge-title">{{ $t('homepage.knowledge-section.web-section.title') }}</h3>
                <p data-aos="zoom-in" class="knowledge-subtitle">{{ $t('homepage.knowledge-section.web-section.subtitle') }}</p>
                <h4 data-aos="zoom-in" class="knowledge-section-title">{{ $t('homepage.knowledge-section.web-section.lang') }}</h4>
                <p data-aos="zoom-in" class="knowledge-lang">Python, Javascript</p>
                <h4 data-aos="zoom-in" class="knowledge-section-title mb-08">{{ $t('homepage.knowledge-section.web-section.tech') }}</h4>
                <li data-aos="zoom-in" v-for="tech in knowlegde.webdev.techs" :key="tech" class="knowledge-tech">{{ tech }}</li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="modal-section">
      <b-modal :active.sync="this.$store.state.isSayHelloTrigger" has-modal-card full-screen :can-cancel="false">
        <contact-modal></contact-modal>
      </b-modal>
    </section>
  </main>
</template>

<script>
import ContactModal from '../components/ContactModal'

export default {
  data () {
    return {
      knowlegde: {
        gamedev: {
          techs: [
            'Unreal Engine',
            'PyGame',
            'WebGL (babylonJS)',
            'Visual Studio',
            'VS Code',
            'PyCharm',
            'Git'
          ]
        },
        softdev: {
          techs: [
            'QT, PyQT5',
            'ElectronJS + React / Vue',
            'MySQL',
            'MongoDB',
            'API / REST API',
            'Visual Studio',
            'VS Code',
            'PyCharm',
            'Git'
          ]
        },
        webdev: {
          techs: [
            'Django',
            'Javscript / JQuery',
            'NodeJS + / Express',
            'VueJS / React',
            'REST API',
            'MongoDB',
            'MySQL',
            'SASS',
            'WebGL',
            'VS Code',
            'PyCharm',
            'Git'
          ]
        }
      }
    }
  },
  components: {
    ContactModal
  }
}
</script>
