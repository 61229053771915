<template>
  <div id="app">
    <header-navbar />
    <router-view />
    <Footer />
  </div>
</template>

<style lang="scss">
</style>

<script>
import HeaderNavbar from './components/HeaderNavbar'
import Footer from './components/Footer'

export default {
  components: {
    HeaderNavbar,
    Footer
  }
}
</script>
